<template>
  <div class="view-lucky-detail">
     <CompHeader title='我的记录'>
      <div class="comp-header-right-box">
        <div class="coin"></div>
      </div>
    </CompHeader>
    <div class="tab-box">
        <div class="tab-item" :class="tabindex === 0 ? 'tab-item-active' : ''" @click="tabChange(0)">
          我的记录
        </div>
        <div class="tab-item" :class="tabindex === 1 ? 'tab-item-active' : ''" @click="tabChange(1)">
          欧皇记录
        </div>
      </div>
    <div class="center-box" >
      <div class="user-list-wrap">
        <div class="user-list-box">
          <div class="user-list">
            <div v-for="item,index in optionList" class="user-item" :class="{'user-item-active':item.get_award.id==$route.query.id}" :key="index">
             <div class="box-top" v-if="tabindex === 0">
                <div class="date-box">
                {{item.updated_at}}
                </div>
                <div class="status-box" >
                   <p :class="item.award_id === item.get_award_id?'text-success':'text-fail'">{{item.award_id === item.get_award.id?"升级成功":"升级失败"}}
                    </p>  
                </div>
             </div>
              <div class="box-top" v-else>
                <div class="user-box" >
                  <img :src="item.user.avatar" alt="">
                  <div class="name-box">{{item.user.name}}</div>
                </div>
                <div class="date-box">
                {{item.updated_at}}
                </div>
               
             </div>
             <div class="box-bottom">
               <div class="img">
                <img style="width:100%;height:100%;" :src="item.get_award?.cover" alt="">
              </div>
               <div class="foo">
                饰品价格：{{item.get_bean}}
              </div>
              <div  class="odd">
                概率：{{ parseInt(item?.percent).toFixed(0) }}%
              </div>
             </div>
            </div>
          </div>
          
        </div>
       
      </div>
    </div>
    <!-- <div class="luckypagination">
      <Pagination :total="PageNum" :page.sync="page_num" :limit.sync="per_page" @pagination="handleCurrentChange" />
    </div> -->
  </div>
</template>

<script>
import { LuckyWin, Luckyhistory } from "@/network/api.js";
export default {
  name: "Open",
  data() {
    return {
      tabindex: 0,
      optionList:[],
      winList: [],
      //分页
      per_page: 0,
      PageNum: 0,
      page_num: 1, //页码
      centerBoxVisibleHeight: 0,

    
    };
  },

  //activated
  created() {
      // 获取 center-box 元素
    const centerBox = document.querySelector('.center-box');

    // 获取窗口的高度
    const windowHeight = window.innerHeight;

    // 获取 center-box 元素的顶部位置
    // const centerBoxTop = centerBox.offsetTop;

    // 计算 center-box 的可视高度
    this.centerBoxVisibleHeight = windowHeight - 350;

    // console.log('Center-box 可视高度:', this.centerBoxVisibleHeight);
    
    this.getList();
    // this.getLuckywinList();
  },

  methods: {
     // tab切换
    tabChange(i) {
      this.tabindex = i;
      if (i === 1) {
        this.getLuckywinList();
      }else {
        this.getList();

      }
    },
    handleCurrentChange(val) {
       if (i === 1) {
        this.getLuckywinList(val.page);
      }else {
        this.getList(val.page);

      }
    },
   
  
    getList() {
      let id = this.$route.query.id;
      // let id = 8294;
      Luckyhistory(id).then((res) => {
        this.optionList = res.data.data;
      });
      console.log("this.optionList",this.optionList)
    },
     getLuckywinList() {
      // let id = this.$route.query.id;
      let id = 8294;

      LuckyWin(id).then((res) => {
        // this.winList = res.data.data;
        this.per_page = res.data.data.per_page;
        this.optionList = res.data.data;
        this.PageNum = res.data.data.total;
      });
      console.log("this.winList",this.winList)
    },


  },

};
</script>

<style lang="scss" scoped>
.view-lucky-detail {
  width: 100%;
 .tab-box {
    width: 2.27rem;
    height: 0.42rem;
    background: #161514;
    margin: 0 auto;
    margin-top: 0.2rem;
    display: flex;
    align-items: center;
    font-size: 0.14rem;
    margin-bottom: 0.16rem;
    padding: 0 0.04rem;

    .tab-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      height: 80%;
      font-weight: 400;
      color: #B79F79;
    }
    .tab-item-active {
      font-weight: 500;
      color: #FFFFFF;
      line-height: 0.16rem;
      text-align: left;
      font-style: normal;
      background: url(../../assets/newImages/record-actable.png) no-repeat;
      background-size: 100% 100%;
      background-position: 0 0;
    }
  }
  .header-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 3;
    font-size: 0.16rem;
    margin-bottom: 0.02rem;
    .back-box2 {
      position: absolute;
      width: 0.25rem;
      height: 0.14rem;
      left: 0.2rem;
      top: 0.02rem;
      z-index: 3;
      background: url(../../assets/images/new/back-icon.png) no-repeat center;
      background-size: 100% 100%;
    }
  }
  .center-box {
    width: 100%;
    position: relative;
    overflow: scroll;

    .user-list-wrap {
      .user-list-box {
        display: flex;
        align-items: center;
        width: 100%;
        // height: 2.2rem;
        .user-list-text {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 0.62rem;
          height: 100%;
          background: linear-gradient(
            to bottom,
            rgba(18, 7, 37, 1),
            rgba(30, 28, 54, 1)
          );
          margin-right: 0.04rem;
          flex-shrink: 0;
          padding: 0 0.2rem;
        }
        .user-list {
          width: 100%;
          display: flex;
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          padding: 0 0.2rem;
          margin-bottom: 0.2rem;
          .user-item {
            width: 100%;
            height: 0.72rem;
            // line-height: 0.72rem;
            border: 0.01rem solid #69552D;
            flex-shrink: 0;
            background: url(../../assets/newImages/history-recode-list.png) no-repeat center;
            background-size: 100% 100%;
            padding: 0.08rem;
            margin-bottom: 0.12rem;
            padding-left: 0.1rem;
            position: relative;
            .box-top {
              width: 100%;
              height: 30%;
              display: flex;
              margin-bottom: 0.02rem;
              justify-content: space-between;
              align-items: center;
              position: relative;
              .date-box{
                font-size: 0.10rem;
                text-transform: none;
              }
              .user-box {
                font-weight: 300;
                font-size: 0.12rem;
                color: #FFFFFF;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                  width: 0.20rem;
                  height: 0.20rem;
                  border-radius: 50%;
                }
                .name-box {
                  margin-left: 0.02rem;
                }
              }
              .status-box {
                position: absolute;
                right: -0.08rem;
                top: -0.08rem;
                width: 0.77rem;
                height: 0.28rem;
                line-height: 0.28rem;
                background: #4B3A00;
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                font-size: 0.12rem;
                text-align: center;
                    .text-success {
                      color: #8FD30F;
                    }
                    .text-fail {
                        color: #B79312;
                    }
              }
            }
            .box-bottom {
              width: 100%;
              height: 80%;
               display: flex;
               justify-content: space-between;
               align-items: center;
             .img {
              width: 0.43rem;
              height: 0.43rem;
              margin-right: 0.14rem;
            }
            .odd {
              font-size: 0.12rem;
              text-align: left;
              margin-right: 0.2rem;
              flex-shrink: 0;
            }
            .foo {
              font-size: 0.12rem;
              text-align: left;

            }
            }
          
          }
          .user-item-active {
            // background: url(../../assets/images/new/bg10-at.png) no-repeat
            //   center;
            // background-size: 100% 100%;
          }
        }
    
      }
    }
  }
  .luckypagination {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0.1rem auto;
}
}
</style>
